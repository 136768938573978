
import roles from "@/types/roles.ts";
import { useCityRelTown } from "@/network/chinaArea";

import { userEditFormRules } from "@/model/userFormRules.ts";
import { useUser, userTypeDic } from "@/network/user";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
} from "vue";
import settings from "@/settings";
export default defineComponent({
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, form, find, save] = useUser(id);
    const [dicCity, dicTown] = useCityRelTown(
      toRef(form, "city_ID"),
      toRef(form, "county_ID"),
      null
    );

    const refForm = ref(null);

    watch(visible, (v) => {
      if (v) {
        find().then((p) => {
          if (!id.value) {
            if (!user.inRoles(roles.prov)) {
              form.city_ID = user.city_ID;
              form.userType_Id = 7;
            }
          }
          setTimeout(() => refForm.value.clearValidate(), 1);
        });
      }
    });

    const doSave = (isApproved) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            save({ params: { isApproved } }).then(() => {
              resolve("success");
              visible.value = false;
              emit("change", form);
            });
          } else {
            reject("error");
          }
        });
      });
    };

    return {
      user,
      roles,
      userTypeDic: userTypeDic(),
      refForm,
      isLoading,
      visible,
      form,
      doSave,
      rules: userEditFormRules(form),
      dicCity,
      dicTown,
    };
  },
});
